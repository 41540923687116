import React from "react"
import { Link, ChipField } from 'react-admin'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { stringify } from 'query-string'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer',
    '& > *': {
      cursor: 'pointer'
    }
  },
}));

export const ResourceLinkField = ({record, source, referencePath}) => {
  const classes = useStyles()
  const id = get(record, 'id')

  if (!record || !id) {
    return null
  }

  return (
    <Link
      to={{
        pathname: `/${referencePath}`,
        search: stringify({
          page: 1,
          itemsPerPage: 25,
          sort: 'id',
          order: 'DESC',
          filter: JSON.stringify({['id']: id}),
          displayedFilters: JSON.stringify({['id']: true})
        }),
      }}
      onClick={event => event.stopPropagation()}
      className={classes.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ChipField
        source={source}
        record={record}
        clickable={false}
      />
    </Link>
  )
}

ResourceLinkField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  referencePath: PropTypes.string.isRequired,
};
