import React from 'react'
import { AppBar as BaseAppBar } from 'react-admin'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import Logo from './Logo'
import {session} from "../../service/UserSession";

const styles = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  username: {
    padding: "0 10px"
  },
}

const CompanyName = styled(Typography)`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const Spacer = styled.span`
  @media screen and (max-width: 768px) {
    display: none;
  }
`

const AppBar = withStyles(styles)(({ classes, ...props }) => {
  const { name } = session.getUser()

  return (
    <BaseAppBar {...props}>
      <CompanyName
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Logo />
      <CompanyName variant="h6" color="inherit">
        <span style={{ fontSize: '25px', marginLeft: '10px' }}>
          WP plugins
        </span>
      </CompanyName>
      <Spacer className={classes.spacer} />

      <Typography color="inherit" className={classes.username} >
        <span>{ name }</span>
      </Typography>

    </BaseAppBar>
  )
})

export default AppBar
