import React, { useState, useCallback, useMemo } from 'react'
import Popover from '@material-ui/core/Popover'
import ArchiveIcon from '@material-ui/icons/Archive'
import LanguageIcon from '@material-ui/icons/Language'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { CSV_DOWLOAD_LOCALES } from '../../../mixin/string'
import styled from 'styled-components'

const StyledTypography = styled(Typography)`
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  ${({ isSelected }) => isSelected && 'background-color: rgba(0, 0, 0, 0.08);'};
`

const options = [
  {
    id: CSV_DOWLOAD_LOCALES.NL,
    label: 'Euro (NL)',
  },
  {
    id: CSV_DOWLOAD_LOCALES.USA,
    label: 'USA',
  },
]

const CsvDownloadFormatToolbar = React.memo(
  ({ selectedLocale = CSV_DOWLOAD_LOCALES.NL, setSelectedLocale }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = useCallback((event) => {
      setAnchorEl(event.currentTarget)
    }, [])

    const handleClose = useCallback(() => {
      setAnchorEl(null)
    }, [])

    const onSelectLocale = useCallback((locale) => {
      setSelectedLocale(locale)
      handleClose()
    }, [])

    const open = useMemo(() => Boolean(anchorEl), [anchorEl])
    const id = useMemo(() => (open ? 'simple-popover' : undefined), [open])

    return (
      <>
        <Tooltip title={'CSV download locale format'}>
          <LanguageIcon onClick={handleClick}>
            <ArchiveIcon />
          </LanguageIcon>
        </Tooltip>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <>
            {options.map((item) => (
              <StyledTypography
                key={item.id}
                isSelected={item.id === selectedLocale}
                onClick={() => onSelectLocale(item.id)}
              >
                {item.label}
              </StyledTypography>
            ))}
          </>
        </Popover>
      </>
    )
  }
)

export default CsvDownloadFormatToolbar
