export const getFormattedError = (body, withTitle) => {
  if ('object' !== typeof body) {
    return null;
  }

  withTitle = undefined === withTitle ? true : withTitle

  const fields = ['hydra:message', 'hydra:description']

  if (withTitle) {
    fields.unshift('hydra:title')
  }

  let error = fields
    .filter(item => body[item])
    .map(item => body[item] + ('.' === body[item].substr(body[item].length - 1) ? '\n' : '. \n'))

  return error.join('')
}