import React from 'react'
import {
  required,
  usePermissions,
  TextInput,
} from 'react-admin'

import { EditGuesser } from '../../theme/components'

export const PluginEdit = (props) => {
  const { permissions } = usePermissions()

  if (!permissions) {
    return null
  }

  return (
    <EditGuesser{...props}>
      <TextInput
        source="slug"
        validate={[required()]}
      />
    </EditGuesser>
  )
}
