import { session, USER_SESSION_STATES } from '../service/UserSession'
import Security from '../service/Permissions'
import { getHttpStatus } from '../service/Api/responseErrorHandler'
import apiProvider from './apiProvider'

export default {
  login: async (params) => {
    const { data } = await apiProvider().authorize(params)

    session.init(data)

    return Promise.resolve()
  },

  logout: async () => {
    await apiProvider().logout()
    session.clear()

    return Promise.resolve()
  },

  checkError: async (error) => {
    const status = getHttpStatus(error)

    if (401 === status || 503 === status) {
      session.clear()

      return Promise.reject()
    }

    return Promise.resolve()
  },

  checkAuth: async () => {
    const sessionState = session.getState()

    if (USER_SESSION_STATES.ACTIVE === sessionState) {
      return Promise.resolve()
    }

    if (USER_SESSION_STATES.NON_AUTHENTICATED === sessionState) {
      session.clear()

      return Promise.reject()
    }

    return Promise.reject()
  },

  getPermissions: () => {
    const roles = session.getRoles() || []


    return 0 === roles.length
      ? Promise.reject()
      : Promise.resolve(new Security(roles))
  },
}
