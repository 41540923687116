import user from '../../component/user'
import notification from '../../component/notification'
import plugin from '../../component/plugin'
import pluginVersion from '../../component/pluginVersion'
import {ROLES} from "../../mixin/user"

export default (permissions) => {

  if (permissions.isGranted(ROLES.ROLE_ADMIN)) {
    return [
      { resource: 'users', label: 'resource.user.menu', ...user},
      { resource: 'notifications', label: 'resource.notification.menu', ...notification},
      { resource: 'plugins', label: 'resource.plugin.menu', ...plugin},
      { resource: 'plugin-versions', label: 'resource.pluginVersion.menu', ...pluginVersion},
    ]
  }

  return []
}
