import React from 'react'

import PropTypes from 'prop-types'
import { Field, Form } from 'react-final-form'
import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate } from 'ra-core'
import { formStyles } from '../../styles'
import { Input } from '../../input/Input'
import { useCallback } from 'react'

const useStyles = makeStyles((theme) => formStyles(theme), {
  name: 'RaLoginForm',
})

export const BaseForm = React.memo((props) => {
  const translate = useTranslate()
  const classes = useStyles(props)

  const validate = useCallback(
    (values) => {
      const errors = { username: undefined, password: undefined }

      if (!values.username) {
        errors.username = translate('ra.validation.required')
      }
      if (!values.password) {
        errors.password = translate('ra.validation.required')
      }
      return errors
    },
    [translate]
  )

  return (
    <Form
      onSubmit={props.onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.form}>
            <div className={classes.input}>
              <Field
                id="username"
                name="username"
                component={Input}
                label={translate('ra.auth.username')}
                disabled={props.loading}
              />
            </div>
            <div className={classes.input}>
              <Field
                id="password"
                name="password"
                component={Input}
                label={translate('ra.auth.password')}
                type="password"
                disabled={props.loading}
                autoComplete="current-password"
              />
            </div>
          </div>
          <CardActions className={classes.actions}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={props.loading}
              className={classes.button}
            >
              {props.loading && (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
              )}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>
        </form>
      )}
    />
  )
})

BaseForm.propTypes = {
  redirectTo: PropTypes.string,
}
