export const roleChoices = () => (
  [
    { id: 'administrator', name: 'Administrator' },
    { id: 'editor', name: 'Editor' },
    { id: 'author', name: 'Author' },
    { id: 'contributor', name: 'Contributor' },
    { id: 'subscriber', name: 'Subscriber' },
  ]
)

export const typeChoices = () => (
  [
    { id: 'dismissable', name: 'Dismissable' },
    { id: 'always', name: 'Always' },
    { id: 'drip', name: 'Drip' },
  ]
)

export const levelChoices = () => (
  [
    { id: 'success', name: 'Success' },
    { id: 'warning', name: 'Warning' },
    { id: 'error', name: 'Error' },
    { id: 'notice', name: 'Notice' },
  ]
)