import React from 'react'
import {
  required,
  minLength,
  CheckboxGroupInput,
  usePermissions,
  SelectInput,
} from 'react-admin'

import RichTextInput from 'ra-input-rich-text'
import { EditGuesser } from '../../theme/components'
import {levelChoices, roleChoices, typeChoices} from "./fields/Choices";

export const NotificationEdit = (props) => {
  const { permissions } = usePermissions()

  if (!permissions) {
    return null
  }

  return (
    <EditGuesser{...props}>
      <SelectInput
        source="level"
        validate={[required()]}
        choices={levelChoices()}
      />
      <SelectInput
        source="type"
        validate={[required()]}
        choices={typeChoices()}
      />
      <CheckboxGroupInput
        source="roles"
        choices={roleChoices()}
        validate={[required()]}
      />
      <RichTextInput source="message" validate={[minLength(3)]}/>
    </EditGuesser>
  )
}
