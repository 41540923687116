export const formStyles = (theme) => (
  {
    form: {
      padding: '0 1.5em 1.5em 1.5em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
      borderRadius: 4
    },
    icon: {
      marginRight: theme.spacing(1),
    },
    instruction: {
      justifyContent: 'center',
      maxWidth: '300px',
      padding: '25px 1.5em 0 1.5em',
    },
    actions: {
      padding: '1.5em 1.5em 1.5em',
      justifyContent: 'center',
    }
  }
)

export const templateStyles = (theme) => (
  {
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundImage:
        'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',
    },
    logo: {
      "& img": {
        maxWidth: '150px',
        marginTop: '3em',
      },
      [theme.breakpoints.down('md')]: {
        "& img": {
          maxWidth: '120px',
          marginTop: '2em',
        },
      },
    },
    footer: {
      position: 'fixed',
      left: '0',
      bottom: '0',
      width: '100%',
      color: '#ffffff',
      textAlign: 'center',
      padding: '10px 0'
    },
    card: {
      minWidth: 300,
      marginTop: '3em',
      borderRadius: 4,
      [theme.breakpoints.down('md')]: {
        maxWidth: '120px',
        marginTop: '2em',
      },
    },
    avatar: {
      margin: '1em 1em 0 1em',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      backgroundColor: theme.palette.secondary[500],
    },
    copy: {
      color: '#d9d4d4',
    }
  }
)

export const loginStyles = () => (
  {
    actions: {
      justifyContent: 'center',
      paddingBottom: '10px',
      paddingTop: '10px',
    },
    actionLink: {
      color: 'rgb(36, 68, 133)',
      textDecoration: 'none',
      fontSize: '0.9rem'
    },
  }
)

export const tfaSecretField = () => ({
  container: {
    margin: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'row wrap',

    '& > *': {
      marginTop: '10px',
    }
  },
  actionLink: {
    color: 'rgb(36, 68, 133)',
    textDecoration: 'none',
    fontSize: '0.9rem',
    display: 'flex',
    alignItems: 'center',
  },
  copyIcon: {
    width: '15px',
    marginRight: '5px',
  },
})