import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import { goBack } from 'react-router-redux'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-admin'
import ImageIcon from '@material-ui/icons/ArrowBack'

const BackButton = ({ variant, color, text, ...props }) => {
  const handleClick = () => {
    props.goBack()
  };

  const trans = useTranslate()

  return (
    <Button
      variant={variant}
      color={color}
      onClick={handleClick}
      label={text}
    >
      <ImageIcon />
      {trans(text)}
    </Button>
  )
}

BackButton.defaultProps = {
  variant: 'outlined',
  text: 'theme.back',
  color: 'primary'
};

BackButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string
};

export default connect(null, {
  goBack,
})(BackButton);