import React from 'react'
import {
  required,
  usePermissions,
  TextInput, ReferenceInput, SelectInput,
} from 'react-admin'

import { EditGuesser } from '../../theme/components'

export const PluginVersionEdit = (props) => {
  const { permissions } = usePermissions()

  if (!permissions) {
    return null
  }

  return (
    <EditGuesser{...props}>
      <ReferenceInput
        source="plugin"
        reference="plugins"
        label="resource.plugin.field"
        filterToQuery={searchText => ({ email: searchText })}
        format={v => v && v['@id'] ? v['@id'] : v}
        sort={{ field: 'slug', order: 'ASC' }}
        validate={[required()]}
      >
        <SelectInput optionText="slug" />
      </ReferenceInput>
      <TextInput
        source="slug"
        label="resource.pluginVersion.slug"
        validate={[required()]}
      />
    </EditGuesser>
  )
}
