import React, { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { Redirect } from 'react-router'
import { useLocation, Link } from 'react-router-dom'
import styled from 'styled-components'
import { usePermissions } from 'react-admin'

import Button from '@material-ui/core/Button'
import DefaultSubMenuIcon from '@material-ui/icons/ChevronRight'

import { PaperCard } from '../../theme/components'
import { menuItems } from '../../theme/menu'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledButton = styled(Button)`
  margin-right: 10px !important;
  margin-bottom: 10px !important;
`

const BtnWrapper = styled.span`
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
`

const TextLabel = styled.span`
  margin-top: 7px;
`

export const Dashboard = () => {
  const location = useLocation()
  const translate = useTranslate()
  const { permissions } = usePermissions()

  const renderBtn = useCallback(
    (item) => (
      <StyledButton
        key={item.resource}
        component={Link}
        to={{
          pathname: `/${item.resource}`,
        }}
        variant="outlined"
        color="primary"
        size="small"
      >
        <BtnWrapper>
          {item.icon ? <item.icon /> : <DefaultSubMenuIcon />}
          <TextLabel>{translate(item.label)}</TextLabel>
        </BtnWrapper>
      </StyledButton>
    ),
    [translate]
  )

  if (!permissions) {
    return null
  }

  const items = menuItems(permissions)

  if ('/' === location.pathname) {
    return <Redirect to="/dashboard" />
  }

  return (
    <PaperCard title="resource.dashboard.title" square={false}>
      <Wrapper>
        {Boolean(items.length) && items.map(renderBtn)}
      </Wrapper>
    </PaperCard>
  )
}
