import polyglotI18nProvider from 'ra-i18n-polyglot'
// import { resolveBrowserLocale } from 'react-admin'
import enDefault from 'ra-language-english';
import nlDefault from 'ra-language-dutch';

import nl from './nl'
import en from './en'

const messages = {
  nl: {...nlDefault, ...nl},
  en: {...enDefault, ...en}
}

const fallbackLocale = 'en'

// NL locale
// export const Provider = polyglotI18nProvider(locale =>
//   messages[undefined === messages[locale] ? fallbackLocale : locale],
//   resolveBrowserLocale()
// );

export const Provider = polyglotI18nProvider(() =>
  messages[fallbackLocale],
  fallbackLocale
)
