import _ from 'lodash'

export const USER_SESSION_STATES = {
  NON_AUTHENTICATED: 'NON_AUTHENTICATED',
  NEEDS_REFRESHING: 'NEEDS_REFRESHING',
  SOON_EXPIRED: 'SOON_EXPIRED',
  ACTIVE: 'ACTIVE',
}

class UserSession {
  getUser = () => {
    return {
      uri: localStorage.getItem('userUri'),
      name: localStorage.getItem('userName'),
    }
  }

  getRoles = () => {
    try {
      return JSON.parse(localStorage.getItem('userRoles'))
    } catch (e) {
      return []
    }
  }

  getUserId = () => {
    const uri = localStorage.getItem('userUri')

    if (!uri) {
      return null
    }

    return uri.replace('/api/users/', '')
  }

  get = (key) => {
    const val = localStorage.getItem(key)

    return val || null
  }

  getExp = () => {
    return localStorage.getItem('exp') || null
  }

  getExpRefresh = () => {
    return localStorage.getItem('expRefresh') || null
  }

  getRefreshedAt = () => {
    const refreshedAt = localStorage.getItem('refreshedAt')

    if (!refreshedAt) {
      return null
    }

    return parseInt(refreshedAt)
  }

  init = (data) => {
    const user = _.get(data, 'user')

    localStorage.setItem('exp', _.get(data, 'exp'))
    localStorage.setItem('expRefresh', _.get(data, 'expRefresh'))
    localStorage.setItem('refreshedAt', Math.floor(Date.now() / 1000).toString())
    localStorage.setItem('userName', user.fullName || '')
    localStorage.setItem(
      'userRoles',
      JSON.stringify(
        user.roles && user.roles.length ? user.roles : []
      )
    )
    localStorage.setItem('userUri', user['@id'] || null)
  }

  clear = () => {
    localStorage.removeItem('exp')
    localStorage.removeItem('expRefresh')
    localStorage.removeItem('refreshedAt')
    localStorage.removeItem('userName')
    localStorage.removeItem('userRoles')
  }

  getState = () => {
    const exp = this.getExp()
    const expRefresh = this.getExpRefresh()
    const refreshedAt = this.getRefreshedAt()
    const now = Math.floor(Date.now() / 1000)

    if (!exp || !expRefresh || !refreshedAt || ((expRefresh - 20) < now)) {
      return USER_SESSION_STATES.NON_AUTHENTICATED
    }

    if ((exp - 20) < now) {
      return USER_SESSION_STATES.NEEDS_REFRESHING
    }

    const expStart = Math.floor(refreshedAt + ((exp - refreshedAt)*0.3))

    if (expStart < now) {
      return USER_SESSION_STATES.SOON_EXPIRED
    }

    return USER_SESSION_STATES.ACTIVE
  }
}

export const session = new UserSession()

export default UserSession