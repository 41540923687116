import React from 'react';

import { ROLE_LABELS } from "../../../mixin/user";

export const RolesField = ({ record }) => (
  <>
      {(record.roles || []).map((role, i) => (
          <span key={i}>
            {ROLE_LABELS[role]}
            {`${i !== record.roles.length - 1 ? ', ' : ''}`}
          </span>
      ))}
  </>
)
