import React from 'react'
import {
  required,
  minLength,
  PasswordInput,
  CheckboxGroupInput,
  usePermissions,
  SelectInput,
} from 'react-admin'

import { InputGuesser } from '@api-platform/admin'
import { roleOptions } from '../../mixin/user'
import { generatePassword } from '../../mixin/string'
import { CreateGuesser } from '../../theme/components'

export const UserCreate = (props) => {
  const { permissions } = usePermissions()

  if (!permissions) {
    return null
  }

  return (
    <CreateGuesser{...props}>
      <InputGuesser source="email" inputProps={{ autoComplete: 'off' }} />
      <InputGuesser source="firstName" inputProps={{ autoComplete: 'off' }} />
      <InputGuesser source="lastName" inputProps={{ autoComplete: 'off' }} />
      <CheckboxGroupInput
        source="roles"
        choices={roleOptions(permissions)}
        validate={[required()]}
      />
      <SelectInput
        source="language"
        validate={[required()]}
        choices={[
          { id: 'NL', name: 'Dutch' },
          { id: 'EN', name: 'English' },
        ]}
      />
      <InputGuesser source="active" defaultValue={true} />
      <PasswordInput
        label="Password"
        source="plainPassword"
        defaultValue={generatePassword()}
        inputProps={{ autoComplete: 'new-password' }}
        validate={[minLength(8, `Check if password is not less then 8 characters long`)]}
      />
    </CreateGuesser>
  )
}
