import React, { useCallback } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslate } from 'ra-core'

import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { Input } from '../../input/Input'

import { formStyles } from '../../styles'

const useStyles = makeStyles((theme) => formStyles(theme), {
  name: 'RaLoginForm',
})

export const QRCodeForm = React.memo((props) => {
  const classes = useStyles(props)
  const translate = useTranslate()

  const validate = useCallback(
    (values) => {
      const errors = { code: undefined }

      if (!values.code) {
        errors.code = translate('ra.validation.required')
      }

      return errors
    },
    [translate]
  )

  return (
    <Form
      onSubmit={props.onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div
            className={classes.input}
            style={{
              maxWidth: 200,
              textAlign: 'center',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Field
              id="code"
              name="code"
              component={Input}
              label="Enter code"
              type="number"
              disabled={props.loading}
            />
          </div>
          <CardActions className={classes.actions}>
            <Button variant="contained" type="submit" color="primary">
              SEND
            </Button>
          </CardActions>
        </form>
      )}
    />
  )
})
