import React from 'react'
import {
  // Toolbar,
  SaveButton,
  DeleteButton
} from 'react-admin'
import PropTypes from 'prop-types'
import BackButton from '../button/BackButton'
import { withStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Toolbar from '@material-ui/core/Toolbar'

const styles = {
  toolbar: {
    justifyContent: "space-between",
    '& .ra-delete-button': {
      marginRight: "3em"
    }
  }
};

export const ResourceToolbar = withStyles(styles)(({showBack, showDelete, classes, mutationMode, ...otherProps}) => (
  <Toolbar className={classes.toolbar}>
    {showBack && (
      <BackButton {...otherProps} />
    )}

    <Box>
      {showDelete && (
        <DeleteButton mutationMode={mutationMode} {...otherProps} />
      )}

      <SaveButton {...otherProps} />
    </Box>
  </Toolbar>
));

ResourceToolbar.defaultProps = {
  showDelete: false,
  showBack: true
};

ResourceToolbar.propTypes = {
  showDelete: PropTypes.bool,
  showBack: PropTypes.bool
};