import React from "react";
import { useTranslate } from 'react-admin'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import { raElementGenerateKey } from '../../../mixin/string'

const useStyles = makeStyles({
  th: {
    width: '130px',
    fontWeight: 'bold'
  },
})

export const ListGridRow = ({children, title, component, scope}) => {
  const classes = useStyles()
  const translate = useTranslate()

  let key = raElementGenerateKey(children)

  return (
    <TableRow key={`Table-Row-${key}`}>
      <TableCell className={classes.th} component={component} scope={scope}>{title ? translate(title) : ''}</TableCell>
      <TableCell align="left">{children}</TableCell>
    </TableRow>
  )
}

ListGridRow.propTypes = {
  // key: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
}

ListGridRow.defaultProps = {
  component: 'th',
  scope: 'row'
}
