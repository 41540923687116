import _ from 'lodash'
import { AUTHORIZATION_TOKEN_ERRORS } from './auth.interceptor'
import { BASE_API_URL } from './routes'

export const shouldRefreshOn401 = async (error) => {
  const status = getHttpStatus(error)
  const requestUrl = _.get(error, 'response.request.responseURL')

  if (401 !== status || `${BASE_API_URL}/token/refresh` === requestUrl) {
    return false
  }

  let data = {}

  try {
    data = error.response.data || await error.response.json()
  } catch (e) {
    return true
  }

  const origin = data ? _.get(data, 'origin') : null

  return null !== origin && AUTHORIZATION_TOKEN_ERRORS.includes(origin)
}

/**
 * @return {Number|null}
 */
export const getHttpStatus = (error) => _.get(error, 'status', _.get(error, 'error.response.status', _.get(error, 'response.status')))