import React from "react"
import { ChipField, Link, useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles'
import { stringify } from 'query-string'

const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer',
    '& > *': {
      cursor: 'pointer'
    }
  },
}));

const isValueInvalid = (value) => (
  !value
  || (
    'boolean' !== typeof value
    && 'number' !== typeof value
    && 'string' !== typeof value
    && !Array.isArray(value)
  )
)

const pathMap = (path, filterType = 'primitive') => {
  let chips = path.split('.')

  let map = {
    origin: path,
    first: chips[0],
    last: chips[chips.length - 1],
    isSimple: 1 === chips.length || 'primitive' === filterType,
    suggested: path
  }

  // need to use uri instead of integer id (hydra provider limitation)
  // so suggested path will be different then origin
  if ('id' === map.last && 'collection' === filterType) {
    chips.splice(-1, 1)
    map.suggested = `${chips.join('.')}.@id`
  }

  return map
}

const getValue = (record, path, filterType = 'primitive') => {
  if (_.isFunction(path)) {
    return path(record)
  }

  const {origin, suggested} = pathMap(path, filterType)

  return _.get(record, suggested, _.get(record, origin, null))
}

// We have a limitation with path nesting level.
// The max level is 2 as we don't have any filters with a bigger nested levels (partly because of performance issues).
// Otherwise pathMap need some refactoring
const getFilter = (record, path, filterType = 'primitive') => {
  const value = getValue(record, path, filterType)

  if (isValueInvalid(value)) {
    return null
  }

  const {isSimple, first, last} = pathMap(path, filterType)

  return isSimple
    ? {[last]: value}
    : {[first] : {[last] : Array.isArray(value) ? value : [value]}}
}

export const ReferenceListFilterField = ({record, valuePath, titlePath, listUri, filterType, pagination, ordering}) => {
  const classes = useStyles()
  const translate = useTranslate()

  if (!record) {
    return null
  }

  const filter = getFilter(record, valuePath, filterType)

  if (!filter) {
    return (
      <ChipField
        source="value"
        record={{ value: translate('resources.empty') }}
      />
    )
  }

  return (
    <Link
      to={{
        pathname: `${listUri}`,
        search: stringify({
          filter: JSON.stringify(filter),
          displayedFilters: JSON.stringify({[valuePath]: true}),
          ...pagination,
          ...ordering
        }),
      }}
      onClick={event => event.stopPropagation()}
      className={classes.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ChipField
        source={titlePath}
        record={record}
      />
    </Link>
  )
}

ReferenceListFilterField.propTypes = {
  record: PropTypes.object,
  listUri: PropTypes.string.isRequired,
  titlePath: PropTypes.string.isRequired,
  valuePath: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number, PropTypes.func]).isRequired,
  component: PropTypes.string,
  pagination: PropTypes.object,
  ordering: PropTypes.object,
}

ReferenceListFilterField.defaultProps = {
  // probably we will have more components in the future
  component: 'chip',
  addLabel: true,
  filterType: 'primitive',
  pagination: {
    page: 1,
    itemsPerPage: 50,
  },
  ordering: {
    sort: 'id',
    order: 'DESC',
  },
}
