import React from 'react'
import {
  required,
  minLength,
  CheckboxGroupInput,
  usePermissions,
  SelectInput, ReferenceInput,
} from 'react-admin'

import { CreateGuesser } from '../../theme/components'
import RichTextInput from 'ra-input-rich-text'
import {levelChoices, roleChoices, typeChoices} from "./fields/Choices"
import { FormDataConsumer } from 'react-admin'
import { useForm } from 'react-final-form'

const PluginVersion = ({formData, ...props}) => {
  if (!formData?.plugin) {
    return null
  }

  const format = (v) => {
    return v && v['@id'] ? v['@id'] : v
  }

  return (
    <ReferenceInput
      key={formData.plugin}
      source="pluginVersion"
      reference="plugin-versions"
      filter={{ 'plugin.id': formData.plugin }}
      format={format}
      sort={{ field: 'slug', order: 'ASC' }}
      {...props}

    >
      <SelectInput optionText="slug" />
    </ReferenceInput>
  )
}

const Plugin = (props) => {
  const form = useForm()

  return (
    <ReferenceInput
      source="plugin"
      reference="plugins"
      label="resource.plugin.field"
      format={v => v && v['@id'] ? v['@id'] : v}
      sort={{ field: 'slug', order: 'ASC' }}
      validate={[required()]}
      {...props}
      onChange={() => {
        form.change('pluginVersion', null);
      }}
    >
      <SelectInput optionText="slug" />
    </ReferenceInput>
  )
}

export const NotificationCreate = (props) => {
  const { permissions } = usePermissions()

  if (!permissions) {
    return null
  }

  return (
    <CreateGuesser {...props}>
      <Plugin />
      <FormDataConsumer>
        {({
            formData,
            ...rest
          }) => (<PluginVersion key={formData.plugin} formData={formData} {...rest} />)
        }
      </FormDataConsumer>
      <SelectInput
        source="level"
        validate={[required()]}
        choices={levelChoices()}
      />
      <SelectInput
        source="type"
        validate={[required()]}
        choices={typeChoices()}
      />
      <CheckboxGroupInput
        source="roles"
        choices={roleChoices()}
        validate={[required()]}
      />
      <RichTextInput source="message" validate={[minLength(3)]} />
    </CreateGuesser>
  )
}
