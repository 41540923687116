import React from 'react'
import PropTypes from 'prop-types'
import { Edit, SimpleForm } from 'react-admin'
import { ResourceEditToolbar } from '../toolbar/ResourceEditToolbar'
import Introspecter from '@api-platform/admin/lib/esm/Introspecter'

export const IntrospectedEditGuesser = ({
  fields,
  readableFields,
  writableFields,
  schema,
  schemaAnalyzer,
  children,
  toolbar,
  redirect,
  variant,
  ...props
}) => {
  return (
    <Edit {...props}>
      <SimpleForm variant={variant} toolbar={toolbar} redirect={redirect}>{children}</SimpleForm>
    </Edit>
  )
}

export const EditGuesser = (props) => (
  <Introspecter component={IntrospectedEditGuesser} {...props} />
)

EditGuesser.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  resource: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

EditGuesser.defaultProps = {
  toolbar: <ResourceEditToolbar />,
  undoable: false,
  redirect: 'list',
  actions: null,
  variant: 'outlined',
}
