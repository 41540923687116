import TextField from '@material-ui/core/TextField'
import React, { useState } from "react"
import { useNotify, useTranslate } from "react-admin"
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { tfaSecretField } from '../styles'
import Link from '@material-ui/core/Link'
import { copyToClipboard } from '../../../mixin/clibboard'
import FileCopyIcon from '@material-ui/icons/FileCopy'

const useStyles = makeStyles(tfaSecretField)

export const TfaSecretField = ({secret}) => {
  const [show, setShow] = useState(false)
  const classes = useStyles()
  const notify = useNotify()
  const translate = useTranslate()

  const toggleView = (e) => {
    e.preventDefault()

    setShow(!show)
  }

  const copy = async (e) => {
    e.preventDefault()

    copyToClipboard(secret)
      .then(() => notify('tfa.keyCopied'))
      .catch(() => notify('tfa.keyCopyError', 'error'))
  }

  return (
    <div className={classes.container}>
      <Link
        component="button"
        variant="body1"
        className={classes.actionLink}
        onClick={toggleView}
      >
        { translate( show ? 'tfa.keyHide' : 'tfa.keyShow') }
      </Link>
      { show &&
        <TextField
          fullWidth
          disabled={true}
          value={secret}
        />
      }
      { show &&
        <Link
          component="button"
          variant="body1"
          className={classes.actionLink}
          onClick={copy}
        >
          <FileCopyIcon className={classes.copyIcon}/>
          { translate('tfa.copy') }
        </Link>
      }
    </div>

  )
}

TfaSecretField.propTypes = {
  secret: PropTypes.string.isRequired,
};