import React from 'react'
import {
  TextField,
  Filter,
  usePermissions,
  NumberInput,
  TextInput,
} from 'react-admin'
import { ListGuesser, DateField, ReferenceSingleField } from '../../theme/components'
import {ROLES} from '../../mixin/user'

const ListFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Id" source="id"/>
    <TextInput
      source="slug"
      label="resource.pluginVersion.slug"
    />
  </Filter>
)

export const PluginVersionList = (props) => {
  const { permissions } = usePermissions()

  if (!permissions) {
    return null
  }

  return (
    <ListGuesser
      {...props}
      hasEdit={permissions.isGranted(ROLES.ROLE_ADMIN)}
      filters={<ListFilter permissions={permissions} />}
      filter={{
        properties: [
          'id',
          'slug',
          'createdAt',
        ],
        'properties[plugin]': ['id', 'slug'],
      }}
      rowClick={permissions.isGranted(ROLES.ROLE_ADMIN) ? 'edit' : null}
      bulkActionButtons={false}
    >
      <TextField
        source="originId"
        label="id"
        sortBy="id"
        sort={{ field: 'id', order: 'DESC' }}
      />
      <TextField source="slug" sortBy="slug" label="resource.pluginVersion.slug"/>
      <ReferenceSingleField {...props} source="plugin.slug" referencePath="/plugins" label="resource.plugin.field"/>
      <DateField source="createdAt" sortBy="createdAt"/>
    </ListGuesser>
  )
}
