import _ from 'lodash'
import { ROLES } from "../../mixin/user";
import { session, USER_SESSION_STATES } from "../UserSession";

class Security {
  roles

  constructor(roles) {
    this.roles = _.isArray(roles) ? roles : []
  }

  isGranted = (role) => {
    // check many roles
    if (_.isArray(role)) {
      for (let k in role) {
          if (this.isGranted(role[k])) {
            return true
          }
      }

      // _.forEach(role, (subRole) => {
      //   console.log('INSITE');
      //   console.log(subRole);
      //
      //   if (this.isGranted(subRole)) {
      //     return true
      //   }
      // })

      return false
    }

    if (ROLES.IS_AUTHENTICATED_FULLY === role) {
      return USER_SESSION_STATES.NON_AUTHENTICATED !== session.getState()
    }

    return this.roles.includes(role)
  }
}

export default Security
