import React from "react";
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import PropTypes from 'prop-types'

export const ListGrid = ({children, areaLabel}) => (
  <Table aria-label={areaLabel}>
    <TableBody>{children}</TableBody>
  </Table>
)

ListGrid.propTypes = {
  areaLabel: PropTypes.string,
  children: PropTypes.node,
};

ListGrid.defaultProps = {
  areaLabel: 'simple table',
}