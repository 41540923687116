import React from "react"
import { ChipField, Link, useTranslate } from 'react-admin'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { stringify } from 'query-string'

const useStyles = makeStyles(() => ({
  link: {
    cursor: 'pointer',
    '& > *': {
      cursor: 'pointer'
    }
  },
}));

export const ReferenceSingleField = ({record, source, referencePath, link, filter, displayedFilter, canLink}) => {
  const name = source.slice(0, source.indexOf('.'))
  const embedded = get(record, name, null)
  const classes = useStyles()
  const translate = useTranslate()
  const primitive = displayedFilter === filter && 'id' === filter

  if (!embedded) {
    return (
      <Typography paragraph>
        { translate('theme.emptyRef') }
      </Typography>
    )
  }

  if (!canLink) {
    return (
      <ChipField
        source={source}
        record={record}
      />
    )
  }

  const id = get(embedded, primitive ? 'id' : '@id', null)
  const filterValue = primitive ? id : {"id": [id]}

  // todo@alexbuturlakin: Implement more types
  if ('list' !== link) {
    return null
  }

  return (
    <Link
      to={{
        pathname: `${referencePath}`,
        search: stringify({
          page: 1,
          itemsPerPage: 25,
          sort: 'id',
          order: 'DESC',
          filter: JSON.stringify({[filter]: filterValue}),
          displayedFilters: JSON.stringify({[displayedFilter]: true})
        }),
      }}
      onClick={event => event.stopPropagation()}
      className={classes.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ChipField
        source={source}
        record={record}
      />
    </Link>
  )
}

ReferenceSingleField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  referencePath: PropTypes.string.isRequired,
  link: PropTypes.string,
  filter: PropTypes.string,
  displayedFilter: PropTypes.string,
  canLink: PropTypes.bool,
};

ReferenceSingleField.defaultProps = {
  link: 'list',
  filter: 'id',
  displayedFilter: 'id',
  addLabel: true,
  canLink: true,
};
