import React from "react";
import { useTranslate } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  title: {
    marginBottom: '0.5em',
  }
}));

const StyledPapery = styled(Paper)`
  margin-top: 10px;
`

export const PaperCard = ({title, children, variant, square=true}) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <StyledPapery className={classes.paper} variant={variant} square={square}>
      <Typography className={classes.title} variant="h6">{translate(title)}</Typography>
      {children}
    </StyledPapery>
  );
}

PaperCard.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.string,
  children: PropTypes.node,
};

PaperCard.defaultProps = {
  variant: 'outlined',
}