import React, { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import {
  useAuthProvider,
  useNotify,
  useSafeSetState,
} from 'ra-core'
import { useHistory } from 'react-router-dom'
import { useCallback } from 'react'
import errorMessageHandler from '../../../../service/Api/errorMessageHandler'
import { BaseForm } from './BaseForm'
import { QRImageForm } from './QRImageForm'
import { QRCodeForm } from './QRCodeForm'

const BASE_VIEW = 'base-view'
const QR_IMAGE_VIEW = 'qr-image-view'
const QR_CODE_VIEW = 'qr-code-view'

export const LoginForm = React.memo((props) => {
  const [currentView, setCurrentView] = useState(BASE_VIEW)
  const [qrImage, setQrImage] = useState(null)
  const [tfaSecret, setTfaSecret] = useState(null)
  const [formValues, setFormValues] = useState({})
  const history = useHistory()
  const authProvider = useAuthProvider()
  const [loading, setLoading] = useSafeSetState(false)
  const notify = useNotify()

  const { redirectTo } = props

  const handleLoginError = useCallback((e) => {
    switch (e.response.data.origin) {
      case 'authorization.tfa.not_setup': {
        const { qr, secret } = e.response.data.extra

        setQrImage(qr)
        setTfaSecret(secret)
        setCurrentView(QR_IMAGE_VIEW)
        break
      }

      case 'authorization.tfa.code_required': {
        setCurrentView(QR_CODE_VIEW)
        break
      }

      case 'authorization.tfa.code_incorrect': {
        notify('Code is incorrect', 'error', {}, false, 10000)
        break
      }

      default: {
        notify(errorMessageHandler(e), 'error', {}, false, 10000)
      }
    }
  }, [notify])

  const login = useCallback(
    (params) =>
      authProvider
        .login(params)
        .then((ret) => {
          window.location.href = `${window.location.origin}/#/dashboard`
          window.location.reload()
          return ret
        })
        .catch(handleLoginError),
    [authProvider, handleLoginError]
  )

  const submitLogin = useCallback(
    (values) => {
      setFormValues(values)
      setLoading(true)
      login(values, redirectTo)
        .then(() => {
          setLoading(false)
          history.push(redirectTo)
        })
        .catch((error) => {
          setLoading(false)
          notify(
            typeof error === 'string'
              ? error
              : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
            'warning'
          )
        })
    },
    [history, login, notify, redirectTo, setLoading]
  )

  const submitQRImageForm = useCallback(() => setCurrentView(QR_CODE_VIEW), [])

  const submitQRCodeForm = useCallback(
    ({ code }) => submitLogin({ ...formValues, code }),
    [formValues, submitLogin]
  )

  const VIEWS = useMemo(
    () => ({
      [BASE_VIEW]: <BaseForm onSubmit={submitLogin} />,
      [QR_IMAGE_VIEW]: (
        <QRImageForm qrImage={qrImage} tfaSecret={tfaSecret} onSubmit={submitQRImageForm} />
      ),
      [QR_CODE_VIEW]: <QRCodeForm onSubmit={submitQRCodeForm} loading={loading} />,
    }),
    [qrImage, loading, submitLogin, submitQRImageForm, submitQRCodeForm]
  )

  return VIEWS[currentView]
})

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
}
