import React from 'react'
import { Form } from 'react-final-form'

import CardActions from '@material-ui/core/CardActions'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import { formStyles } from '../../styles'
import { TfaSecretField } from '../../field/TfaSecretField'

const useStyles = makeStyles((theme) => formStyles(theme), {
  name: 'RaLoginForm',
})

export const QRImageForm = React.memo((props) => {
  const classes = useStyles(props)

  if (!props.qrImage) {
    return null
  }

  return (
    <Form
      onSubmit={props.onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div style={{ textAlign: 'center' }}>
            <img src={props.qrImage} alt="QR Image"/>
          </div>

          {props.tfaSecret &&
            <TfaSecretField secret={props.tfaSecret} />
          }

          <CardActions className={classes.actions}>
            <Button variant="contained" type="submit" color="primary">
              Next
            </Button>
          </CardActions>
        </form>
      )}
    />
  )
})
