import React from "react"
import { Datagrid, useTranslate, Loading, ShowButton, EditButton } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Inbox from '@material-ui/icons/Inbox'

const useStyles = makeStyles({
  message: {
    textAlign: 'center',
    opacity: 0.5,
    margin: '0 1em',
  },
  icon: {
    width: '9em',
    height: '9em',
  },
  toolbar: {
    textAlign: 'center',
    marginTop: '2em',
  },
  loading: {
    '& > div': {
      paddingBottom: '3em'
    }
  }
});

export const DataGrid = ({expand, rowClick, children, hasShow, hasEdit, hasList, syncWithLocation, loaded, ids, emptyText, bulkActionButtons, showEmpty, ...restProps}) => {
  const classes = useStyles()
  const translate = useTranslate()

  if (!loaded) {
    return (<Loading className={classes.loading}/>)
  }

  if (0 === ids.length) {
    return (
      <div className={classes.message}>
        {showEmpty &&
          <>
            <Inbox className={classes.icon}/>
            <Typography variant="h4" paragraph>
            {translate(emptyText)}
            </Typography>
          </>
        }
      </div>
    )
  }

  return (
    <Datagrid expand={expand} rowClick={rowClick} {...{...restProps, ids, loaded}}>
      {children}
      {!rowClick && hasShow && <ShowButton/>}
      {!rowClick && hasEdit && <EditButton/>}
    </Datagrid>
  )
}

DataGrid.defaultProps = {
  emptyText: 'theme.emptyList'
};

DataGrid.propTypes = {
  emptyText: PropTypes.string,
};
