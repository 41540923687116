import React from 'react'
import { RolesField } from "./fields/RolesField"
import {
  TextField,
  Filter,
  FunctionField,
  usePermissions,
  CheckboxGroupInput,
  SelectInput,
} from 'react-admin';
import { ListGuesser, DateField } from '../../theme/components'
import {ROLES} from '../../mixin/user'
import {levelChoices, roleChoices, typeChoices} from "./fields/Choices";
import {displayLongText, removeHtmlTags} from "../../mixin/string";
import {LevelField} from "./fields/LevelField";

const ListFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      source="level"
      choices={levelChoices()}
    />
    <SelectInput
      source="type"
      choices={typeChoices()}
    />
    <CheckboxGroupInput
      source="roles"
      choices={roleChoices()}
    />
  </Filter>
)

export const NotificationList = (props) => {
  const { permissions } = usePermissions()

  if (!permissions) {
    return null
  }

  return (
    <ListGuesser
      {...props}
      hasEdit={permissions.isGranted(ROLES.ROLE_ADMIN)}
      filters={<ListFilter permissions={permissions} />}
      filter={{
        properties: [
          'id',
          'level',
          'type',
          'roles',
          'message',
          'createdAt',
        ],
        'properties[pluginVersion]': ['id', 'slug'],
        'properties[plugin]': ['id', 'slug'],
      }}
      rowClick={permissions.isGranted(ROLES.ROLE_ADMIN) ? 'edit' : null}
    >
      <TextField
        source="originId"
        label="id"
        sortBy="id"
        sort={{ field: 'id', order: 'DESC' }}
      />
      <FunctionField
        render={(record) => {
          return `${record?.plugin?.slug} : ${record?.pluginVersion?.slug || 'not defined'}`
        }}
        label="resource.notification.version"
      />
      <LevelField source="level" {...props} />
      <TextField source="type"/>
      <FunctionField
        render={(record) => {
          return displayLongText(removeHtmlTags(record.message))
        }}
        label="resource.notification.message"
      />
      <RolesField source="roles" />
      <DateField source="createdAt" sortBy="createdAt"/>
    </ListGuesser>
  )
}
