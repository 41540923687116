import { useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import Api from '../service/Api'

export default () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const notify = useNotify()
  const translate = useTranslate()
  const api = useMemo(
    () => new Api(notify, translate, setIsSubmitting),
    []
  )

  return {
    api,
    isSubmitting: isSubmitting,
  }
}