import React, { useState } from 'react'
import { CSV_DOWLOAD_LOCALES } from '../../../mixin/string'
import PropTypes from 'prop-types'
import useApi from '../../../hooks/useApi'
import Button from '@material-ui/core/Button'
import CsvDownloadFormatToolbar from '../../../component/util/CsvDowloadFormatToolbar'
import GetAppIcon from '@material-ui/icons/GetApp'
import styled from 'styled-components'

const CsvDownloadFormatToolbarWrapper = styled.div`
    margin-top: 5px;
    margin-left: 10px;
`

export const Export = ({title, exporter, dataProvider}) => {
  const {api} = useApi()
  const [submitting, setSubmitting] = useState(false)
  const [selectedLocale, setSelectedLocale] = useState(CSV_DOWLOAD_LOCALES.NL)

  const handleExport = async () => {
    setSubmitting(true)

    dataProvider(api)
      .then(response => {
        exporter(response, selectedLocale)
        setSubmitting(false)
      }
    )
  }

  return (
    <>
      <Button color="primary" onClick={handleExport} disabled={submitting}>
        <GetAppIcon/> {title}
      </Button>

      <CsvDownloadFormatToolbarWrapper>
        <CsvDownloadFormatToolbar
          selectedLocale={selectedLocale}
          setSelectedLocale={setSelectedLocale}
        />
      </CsvDownloadFormatToolbarWrapper>
    </>
  )
}

Export.propTypes = {
  exporter: PropTypes.func.isRequired,
  dataProvider: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};