import React from 'react';

export const VersionsField = ({ record }) => (
  <>
      {(record.versions || []).map((version, i) => (
          <span key={i}>
            {version.slug}
            {`${i !== record.versions.length - 1 ? ', ' : ''}`}
          </span>
      ))}
  </>
)

VersionsField.defaultProps = {
  addLabel: true
}
