import * as React from 'react'
import { Template } from './Template'

import PropTypes from 'prop-types'
import { LoginForm } from './form/login'
import { useCheckAuth } from 'ra-core'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

export const Login = (props) => {
  const checkAuth = useCheckAuth()
  const history = useHistory()

  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        history.push('/dashboard')
      })
      .catch(() => {
        // not authenticated, stay on the login page
      })
  }, [checkAuth, history])

  return (
    <Template {...props}>
      <LoginForm {...props} />
    </Template>
  )
}

Login.propTypes = {
  redirectTo: PropTypes.string,
}
