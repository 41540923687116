import React, { cloneElement } from 'react'
import styled from 'styled-components';
import { RolesField } from "./common/RolesField";
import { TopToolbar, CreateButton, sanitizeListRestProps, TextField, Filter, TextInput, NumberInput, usePermissions, CheckboxGroupInput } from 'react-admin';
import { ListGuesser, DateField } from '../../theme/components'
import { makeStyles } from '@material-ui/core/styles'
import {roleOptions, ROLES} from '../../mixin/user'

const useStyles = makeStyles({
  summary: {
    "&>p": {
      margin: '0 3px',
    }
  },
})

const ToolBarContent = styled.div`
  display: flex;
  align-items: center;
`

const ListActions = ({
  className,
  resource,
  filters,
  displayedFilters,
  filterValues,
  basePath,
  showFilter,
  permissions,
  ...rest
}) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    <ToolBarContent>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {permissions.isGranted(ROLES.ROLE_ADMIN) && (
        <CreateButton basePath={basePath} />
      )}
    </ToolBarContent>
  </TopToolbar>
)

const UserSummaryField = ({ record }) => {
  const classes = useStyles()

  if (!record) {
    return null;
  }

  return (
    <div className={classes.summary}>
      {record.fullName &&
        <p>{record.fullName}</p>
      }
      {record.email &&
        <p>{record.email}</p>
      }
    </div>
  )
}

const ListFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Email" source="email" />
    <TextInput label="First Name" source="firstName" />
    <TextInput label="Last Name" source="lastName" />
    <NumberInput label="Id" source="id" />
    <CheckboxGroupInput
      source="roles"
      choices={roleOptions()}
    />
  </Filter>
)

export const UserList = (props) => {
  const { permissions } = usePermissions()

  if (!permissions) {
    return null
  }

  return (
    <ListGuesser
      {...props}
      actions={<ListActions permissions={permissions} />}
      hasEdit={permissions.isGranted(ROLES.ROLE_ADMIN)}
      filters={<ListFilter permissions={permissions} />}
      filter={{
        properties: [
          'id',
          'email',
          'fullName',
          'roles',
          'language',
          'createdAt',
          'modifiedAt',
        ],
      }}
      rowClick={permissions.isGranted(ROLES.ROLE_ADMIN) ? 'edit' : null}
    >
      <TextField
        source="originId"
        label="id"
        sortBy="id"
        sort={{ field: 'id', order: 'DESC' }}
      />
      <UserSummaryField {...props} label="Summary" />
      <RolesField source="roles" />
      <DateField source="createdAt" />
    </ListGuesser>
  )
}
