import * as pathToRegexp from "path-to-regexp";
import sources from './../../config/sources';

const getUri = (source, action, fallback) => {
  return !sources[source]
    ? undefined
    : sources[source]['uri'][action] || sources[source]['uri'][fallback]
}

export const BASE_URL = getUri('midlayer', window.location.hostname, 'wpplugins-admin.tmm-technology.com')
export const BASE_API_URL = `${BASE_URL}/api`

export const getApiRoute = (name, params = {}) => BASE_API_URL + '/' + pathToRegexp.compile(name)(params)