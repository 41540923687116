import * as React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate, MenuItemLink, Responsive, usePermissions } from 'react-admin'
import dashboard from '../../../component/dashboard'
import SubMenu from './SubMenu'
import PropTypes from 'prop-types'
import DefaultIcon from '@material-ui/icons/ViewList'
import DefaultSubMenuIcon from '@material-ui/icons/ChevronRight'
import { menuItems, menuStates } from '../../menu'

const Menu = ({ onMenuClick, dense, logout }) => {
  const { permissions } = usePermissions()
  const items = menuItems(permissions)
  const translate = useTranslate()
  const open = useSelector(state => state.admin.ui.sidebarOpen)

  const [state, setState] = useState(menuStates(items))

  const handleToggle = (menu) => {
    setState(state => ({...state, [menu]: !state[menu] }))
  };

  return (
    <div>
      {' '}
      <MenuItemLink
        key="menuItemDashboard"
        to="/dashboard"
        primaryText={translate('resource.dashboard.menu')}
        onClick={onMenuClick}
        leftIcon={dashboard.icon ? <dashboard.icon /> : <DefaultIcon />}
      />
      { items.map(item => (
        item.hasChildren
          ? (
              <SubMenu
                key={`menuItem${item.resource}`}
                handleToggle={() => handleToggle(item.stateName)}
                isOpen={state[item.stateName]}
                sidebarIsOpen={open}
                name={item.label}
                icon={item.icon ? <item.icon /> : <DefaultSubMenuIcon />}
                dense={dense}
              >
                {/* <MenuItemLink
                  key={`menuItem${item.resource}`}
                  to={`/${item.resource}`}
                  primaryText={translate(item.label)}
                  leftIcon={item.icon ? <item.icon /> : <DefaultIcon />}
                  onClick={onMenuClick}
                  dense={dense}
                  sidebarIsOpen={open}
                /> */}

                {item.subItems.map(subItem => (
                  <MenuItemLink
                    key={`menuSubItem${subItem.resource}`}
                    to={`/${subItem.resource}`}
                    primaryText={translate(subItem.label)}
                    onClick={onMenuClick}
                    leftIcon={subItem.icon ? <subItem.icon /> : <DefaultIcon />}
                  />
                ))}
              </SubMenu>
            )
          : (
                <MenuItemLink
                key={`menuItem${item.resource}`}
                to={`/${item.resource}`}
                primaryText={translate(item.label)}
                leftIcon={item.icon ? <item.icon /> : <DefaultIcon />}
                onClick={onMenuClick}
                dense={dense}
                sidebarIsOpen={open}
              />
            )
      ))}

      <Responsive
        small={logout}
        medium={null}
      />
    </div>
  )
}

Menu.propTypes = {
  dense: PropTypes.bool,
  onMenuClick: PropTypes.func,
};

export default Menu