import React from 'react'
import {
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
} from '@api-platform/admin'
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/esm/hydra/parseHydraDocumentation'
import { Redirect, Route } from 'react-router-dom'
import { immutable } from '../config/fetchHydra'
import _ from 'lodash'
import { BASE_API_URL } from '../service/Api/routes'
import { session, USER_SESSION_STATES } from '../service/UserSession'

const entrypoint = BASE_API_URL

const fetchHydra = (url, options = {}) => {
  // Remove from body immutable fields
  if (options.body) {
    options.body = JSON.stringify(
      _.pickBy(
        JSON.parse(options.body),
        (value, key) => !immutable.includes(key)
      )
    )
  }

  const exchangeId = localStorage.getItem('exchangeId')
  const currencyId = JSON.parse(localStorage.getItem('currencyId'))

  if (exchangeId) {
    options.headers = {
      ...options.headers,
      'Yieldt-Exchange-Id': exchangeId,
    }
  }

  if (currencyId) {
    options.headers = {
      ...options.headers,
      'Yieldt-Viewing-Currency-Id': currencyId,
    }
  }

  return baseFetchHydra(url, {
    ...options,
    credentials: 'include',
    withCredentials: true,
  })
}

// const normalizeFieldById = (field, useNull = false) =>
//   (field.normalizeData = async (value) => {
//     if (!value) {
//       return useNull ? null : ''
//     }
//
//     if (_.isArray(value)) {
//       return value.map((item) => item['@id'])
//     }
//
//     return value['@id'] || value
//   })

export const apiDocumentationParser = async (entrypoint) => {
  // Api
  return parseHydraDocumentation(entrypoint, {
    credentials: 'include',
  }).then(
    // DataProvider customization
    ({ api }) => {
      api.resources.map((resource) => {
        resource.fields.map((field) => {
          // Midlayer doesn't require those fields anymore for all documents

          return field
        })

        return resource
      })

      return { api }
    },
    async (result) => {
      // 401 Error handling
      switch (result.status) {
        case 401:
        case 503:
          session.clear()

          /* eslint-disable */
          return Promise.resolve({
            api: result.api,
            /* eslint-disable react/jsx-key */
            customRoutes: [
              <Route
                path="/"
                render={() => {
                  return USER_SESSION_STATES.ACTIVE === session.getState() ? (
                    <></>
                  ) : (
                    <Redirect to="/login" />
                  )
                }}
              />,
            ],
            /* eslint-enable react/jsx-key */
          })

        default:
          return Promise.reject(result)
      }
    }
  )
}

export const dataProvider = baseHydraDataProvider(
  entrypoint,
  fetchHydra,
  apiDocumentationParser,
  true
)
