import * as React from 'react'
import { useEffect, useMemo, useRef } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { createTheme, makeStyles } from '@material-ui/core/styles'
import { Avatar, Card } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
import { templateStyles } from './styles'
import Notification from '../layout/Notification'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(
  theme => templateStyles(theme),
  { name: 'RaLogin' }
)

export const Template = ({ theme, className, backgroundImage, children, ...rest }) => {
  const muiTheme = useMemo(() => createTheme(theme), [theme])
  const classes = useStyles(rest)
  const location = useLocation()

  let backgroundImageLoaded = false
  const containerRef = useRef(HTMLDivElement)

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage()
    }
  })

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current && ['/login'].includes(location.pathname)) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`
      backgroundImageLoaded = true
    }
  }

  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage
      img.src = backgroundImage
    }
  }

  delete rest['staticContext']

  return (
    <ThemeProvider theme={muiTheme}>
      <div
        className={classnames(classes.main, className)}
        {...rest}
        title=""
        ref={containerRef}
      >
        <div className={classes.logo}>
          <img src="https://tst.tmm-ventures.com/app/uploads/2019/07/tmm-logo-blue.png" alt="wpplugins"/>
        </div>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
          {children}
          <Notification />
        </Card>
        <Grid container spacing={0} className={classes.footer} >
          <Grid item xs={false} sm={false} md={4}>

          </Grid>
          <Grid className={classes.copy} item xs={12} sm={12} md={4}>
            <Typography variant="h6">&copy;wpplugins 2022</Typography>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  )
}

Template.defaultProps = {
  backgroundImage: 'https://tmm.ventures/app/uploads/2019/12/tmm-foto-2-1.jpg',
}