import { createTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const MuiTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#ff4f52',
      main: '#3d8e87', // #4fb79a
      dark: '#329f97',
      contrastText: 'rgba(238,238,238,0.96)',
    },
    secondary: {
      light: '#585c61',
      main: '#2f3337',
      dark: '#060c11',
      contrastText: '#ffffff',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  status: {
    danger: 'orange',
  },
  shape: {
    borderRadius: 8
  },
  overrides: {
    // Forms
    MuiFormControl: {
      marginDense: {
        marginBottom: '15px',
        width: '300px !important',
        '&.ra-rich-text-input': {
          width: '100% !important'
        }
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'transparent',
        "&:hover": {
          backgroundColor: 'transparent'
        },
        "&.Mui-focused": {
          backgroundColor: 'transparent'
        }
      },
      underline: {
        '& .MuiSvgIcon-root': {
          right: '-9px'
        }
      }
    },
    MuiSelect: {
      icon: {
        color: '#4fb79a'
      },
      select: {
        "&:focus": {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiButton: {
      text: {
        color: '#4fb79a'
      }
    },
    // Tables
    MuiTableCell: {
      root: {
        '& a:not(.MuiButton-text)': {
          color: '#2f3337'
        }
      }
    },
    MuiTableRow: {
      head: {
        backgroundColor: 'rgba(47,95,88,0.03)',
        '& > th': {
          color: 'rgba(30,31,56,0.58)',
          fontWeight: 'bold',
          padding: '6px 8px 6px 8px',
          fontSize: '0.8rem'
        },
        '& > th:first-child': {
          padding: '6px 8px 6px 15px'
        }
      },
      hover: {
        '& > td': {
          padding: '6px 8px 6px 8px'
        },
        '& > td:first-child': {
          padding: '6px 8px 6px 15px'
        }
      }
    },
    // Resources
    MuiChip: {
      label: {
        color: '#2f3337'
      }
    },
    // layout
    MuiPaper: {
      elevation1: {
        boxShadow: '2px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 1px rgba(0,0,0,0.12)'
      }
    }
    // Cards

  }
});

export default MuiTheme;