import React from 'react'
import PropTypes from 'prop-types'
import { ResourceToolbar } from './ResourceToolbar'

export const ResourceEditToolbar = props => (
  <ResourceToolbar {...props} />
);

ResourceEditToolbar.defaultProps = {
  showDelete: true
};

ResourceEditToolbar.propTypes = {
  showDelete: PropTypes.bool
};