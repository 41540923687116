import * as React from 'react';
import {
  useCallback,
  useRef,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { InputHelperText } from 'react-admin'
import {
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {
  FieldTitle,
  useInput,
  useChoices,
} from 'ra-core'

import _ from 'lodash'

const sanitizeRestProps = ({
  addLabel,
  allowEmpty,
  alwaysOn,
  basePath,
  choices,
  classNamInputWithOptionsPropse,
  componenInputWithOptionsPropst,
  crudGetMInputWithOptionsPropsatching,
  crudGetOInputWithOptionsPropsne,
  defaultValue,
  filter,
  filterToQuery,
  formClassName,
  initializeForm,
  input,
  isRequired,
  label,
  limitChoicesToValue,
  loaded,
  locale,
  meta,
  onChange,
  options,
  optionValue,
  optionText,
  perPage,
  record,
  reference,
  resource,
  setFilter,
  setPagination,
  setSort,
  sort,
  source,
  textAlign,
  translate,
  translateChoice,
  validation,
  ...rest
}) => rest;

const useStyles = makeStyles(
  theme => ({
    root: {},
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: theme.spacing(1 / 4),
    },
  }),
  { name: 'RaSelectArrayInput' }
)

export const SelectArrayInput = props => {
  const {
    choices = [],
    classes: classesOverride,
    className,
    format,
    helperText,
    label,
    margin = 'dense',
    onBlur,
    onChange,
    onFocus,
    options,
    optionText,
    optionValue,
    parse,
    resource,
    source,
    translateChoice,
    validate,
    variant = 'filled',
    ...rest
  } = props;
  const classes = useStyles(props);
  const inputLabel = useRef(null);
  const [labelWidth, setLabelWidth] = useState(0);
  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });

  const {
    input,
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    source,
    validate,
    ...rest,
  });

  const renderMenuItemOption = useCallback(choice => getChoiceText(choice), [
    getChoiceText,
  ]);

  const renderMenuItem = useCallback(
    choice => {
      return choice ? (
        <MenuItem
          key={getChoiceValue(choice)}
          value={getChoiceValue(choice)}
        >
          {renderMenuItemOption(choice)}
        </MenuItem>
      ) : null;
    },
    [getChoiceValue, renderMenuItemOption]
  );

  return (
    <FormControl
      margin={margin}
      className={classnames(classes.root, className)}
      error={touched && !!error}
      variant={variant}
      {...sanitizeRestProps(rest)}
    >
      <InputLabel
        ref={inputLabel}
        id={`${label}-outlined-label`}
        error={touched && !!error}
      >
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      </InputLabel>
      <Select
        autoWidth
        labelId={`${label}-outlined-label`}
        error={!!(touched && error)}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {(_.isArray(selected) ? selected : [selected])
              .map(item =>
                choices.find(
                  choice => getChoiceValue(choice) === item
                )
              )
              .map(item => (
                <Chip
                  key={getChoiceValue(item)}
                  label={renderMenuItemOption(item)}
                  className={classes.chip}
                />
              ))}
          </div>
        )}
        data-testid="selectArray"
        {...input}
        value={input.value || []}
        {...options}
        labelWidth={labelWidth}
      >
        {choices.map(renderMenuItem)}
      </Select>
      <FormHelperText error={touched && !!error}>
        <InputHelperText
          touched={touched}
          error={error}
          helperText={helperText}
        />
      </FormHelperText>
    </FormControl>
  );
};

SelectArrayInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]).isRequired,
  optionValue: PropTypes.string.isRequired,
  resource: PropTypes.string,
  source: PropTypes.string,
  translateChoice: PropTypes.bool,
};

SelectArrayInput.defaultProps = {
  options: {},
  optionText: 'name',
  optionValue: 'id',
  translateChoice: true,
};
