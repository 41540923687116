import NotificationsIcon from '@material-ui/icons/Notifications'

import { NotificationList } from './NotificationList'
import { NotificationCreate } from './NotificationCreate'
import { NotificationEdit } from './NotificationEdit'

export default {
  list: NotificationList,
  create: NotificationCreate,
  edit: NotificationEdit,
  icon: NotificationsIcon,
}
