import React from 'react';

export const RolesField = ({ record }) => (
  <>
      {(record.roles || []).map((role, i) => (
          <span key={i}>
            {role}
            {`${i !== record.roles.length - 1 ? ', ' : ''}`}
          </span>
      ))}
  </>
)
