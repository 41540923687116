import React from 'react'
import PropTypes from 'prop-types'
import { Create, SimpleForm } from 'react-admin'
import Introspecter from '@api-platform/admin/lib/esm/Introspecter'
import { ResourceCreateToolbar } from '../toolbar/ResourceCreateToolbar'

export const IntrospectedCreateGuesser = ({
  fields,
  readableFields,
  writableFields,
  schema,
  schemaAnalyzer,
  children,
  toolbar,
  redirect,
  variant,
  defaultValue,
  ...props
}) => {

  return (
    <Create {...props}>
      <SimpleForm variant={variant} toolbar={toolbar} redirect={redirect} defaultValue={defaultValue}>{children}</SimpleForm>
    </Create>
  )
}

export const CreateGuesser = (props) => (
  <Introspecter component={IntrospectedCreateGuesser} {...props} />
)

CreateGuesser.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  resource: PropTypes.string.isRequired,
  variant: PropTypes.string,
}

CreateGuesser.defaultProps = {
  toolbar: <ResourceCreateToolbar />,
  redirect: 'list',
  actions: null,
  variant: 'outlined',
  defaultValue: {}
}
