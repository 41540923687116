import React from 'react'
import get from 'lodash/get'

export const generatePassword = () => {
  return Math.random().toString(36).slice(-8)
}

export const convertSnakeCaseToCamelCase = (snakeCaseString) =>
  snakeCaseString.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })

export const convertCamelCaseToText = (camelCaseString) => {
  const text = camelCaseString.replace(/([A-Z])/g, ' $1')

  return `${text[0].toUpperCase()}${text.slice(1)}`
}

export const displayLongText = (text) => {
  if (!text || 40 > text.length) {
    return text
  }

  return text.substring(0, 40) + '...'
}

export const capitalizeFirstLetter = (str) => {
  if ('string' !== typeof str) {
    return ''
  }

  return `${str[0].toUpperCase()}${str.substring(1)}`
}

export const lowercaseFirstLetter = (str) => {
  if ('string' !== typeof str) {
    return ''
  }

  return `${str[0].toLowerCase()}${str.substring(1)}`
}

export const removeHtmlTags = (str) => {
  const regex = /(<([^>]+)>)/gi

  return str.replace(regex, '')
}

export const CSV_DOWLOAD_LOCALES = {
  NL: 'nl-NL',
  USA: 'en-US'
}

export const formatDate = (
  datetime,
  locales = 'nl',
  options = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit'
  }
) => {
  const date = 'string' === typeof datetime ? new Date(datetime) : datetime
  const formatter = new Intl.DateTimeFormat(locales, options)

  return formatter.format(date)
}

export const formatFloat = ({
  number,
  format = null,
  fractionDigits = 2,
  locale = CSV_DOWLOAD_LOCALES.NL
}) => {
  if (!number) {
    return format && 'excel' === format ? '0,00' : '0.00'
  }

  let commonParams = {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  }

  switch (format) {
    case 'excel': {
      return new Intl.NumberFormat(locale, {
        ...commonParams,
        useGrouping: true,
      }).format(number).replace('US', '')
    }

    default:
      return new Intl.NumberFormat(locale, {
        ...commonParams,
        useGrouping: false,
      }).format(number).replace('US', '')
  }
}

export const raElementGenerateKey = (children) => {
  let key = generatePassword()

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child)) {
      return
    }

    key = get(child.props, 'source', key)
  })

  return key
}
