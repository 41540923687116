import axios from 'axios'
import { getApiRoute } from './routes'
import _ from 'lodash'
import errorMessageHandler from './errorMessageHandler'

class Api {
  notify
  translate
  setIsSubmitting
  headers = {}

  bag = { get: {}, create: {}, update: {} }

  constructor(notify = () => {}, translate, setIsSubmitting = () => {}) {
    this.notify = notify
    this.translate = translate
    this.setIsSubmitting = setIsSubmitting
  }

  api = (method) => _.get(this.bag, method, this.up(method))

  up = (method) => {
    let config = {
      headers: this.getHeaders(method),
      withCredentials: true,
    }

    const api = axios.create(config)

    this.bag[method] = api

    return api
  }

  getHeaders = (method) => {
    const exchangeId = localStorage.getItem('exchangeId')
    const currencyId = JSON.parse(localStorage.getItem('currencyId'))

    let headers = {
      accept: 'application/ld+json',
    }

    if (exchangeId) {
      headers = {
        ...headers,
        'Yieldt-Exchange-Id': exchangeId,
      }
    }

    if (currencyId) {
      headers = {
        ...headers,
        'Yieldt-Viewing-Currency-Id': currencyId,
      }
    }

    if (['patch', 'put'].includes(method)) {
      headers['content-type'] = 'application/merge-patch+json'
    } else {
      headers['content-type'] = 'application/ld+json'
    }

    return headers
  }

  post = (url, body, successMessage, errorMessage) => {
    this.setIsSubmitting(true)

    return this.api('post')
      .post(url, body || {})
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          this.notify(response.statusText, 'error')

          throw new Error(response.statusText)
        }

        if (successMessage) {
          this.notify(successMessage)
        }

        this.setIsSubmitting(false)

        return response
      })
      .catch((error) => {
        this.notify(
          errorMessage || errorMessageHandler(error),
          'error',
          {},
          false,
          10000
        )
        this.setIsSubmitting(false)

        throw error
      })
  }

  patch = (url, body, successMessage) => {
    this.setIsSubmitting(true)

    return this.api('patch')
      .patch(url, body)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          this.notify(response.statusText, 'error')

          throw new Error(response.statusText)
        }

        if (successMessage) {
          this.notify(successMessage)
        }

        this.setIsSubmitting(false)

        return response
      })
      .catch((error) => {
        this.notify(errorMessageHandler(error), 'error', {}, false, 10000)
        this.setIsSubmitting(false)

        throw error
      })
  }

  get = (url, params) => {
    this.setIsSubmitting(true)

    return this.api('get')
      .get(url, { params })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          this.notify(response.statusText, 'error')

          throw new Error(response.statusText)
        }

        this.setIsSubmitting(false)

        return response
      })
      .catch((error) => {
        this.notify(errorMessageHandler(error), 'error', {}, false, 10000)
        this.setIsSubmitting(false)

        throw error
      })
  }

  refreshApiToken = async () => {
    return this.api('post')
      .post(getApiRoute('token/refresh'), {})
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          return null
        }

        return response.data
      })
      .catch(() => {
        return null
      })
  }

  authorize = async (params) =>
    this.api('post').post(getApiRoute('token'), params)

  logout = async () => this.api('post').post(getApiRoute('token/kill'))

  refreshMarkets = async (exchangeId) =>
    this.post(
      getApiRoute('exchanges/:id/markets/refresh', { id: exchangeId }),
      {},
      'resource.exchange.refreshMarketsSuccess'
    )

  refreshBalance = async (
    accountId,
    successMessage = 'resource.account.refreshBalanceSuccess'
  ) =>
    this.post(
      getApiRoute('accounts/:id/balance/refresh', { id: accountId }),
      {},
      successMessage
    )

  rebuildHistory = async (
    accountId,
    successMessage = 'resource.account.rebuildHistorySuccess'
  ) =>
    this.post(
      getApiRoute('account-history/:id/rebuild', { id: accountId }),
      {},
      successMessage
    )

  refreshOpenOrders = async (accountId) =>
    this.post(
      getApiRoute('accounts/:id/open-orders/refresh', { id: accountId }),
      {},
      'resource.account.refreshOpenOrdersSuccess'
    )

  refreshAllOpenOrders = async () =>
    this.post(
      getApiRoute('orders/bulk-refresh-open'),
      {},
      'resource.account.refreshAllOpenOrdersSuccess'
    )

  refreshDeposit = async (accountId) =>
    this.post(
      getApiRoute('accounts/:id/deposit/refresh', { id: accountId }),
      {},
      'resource.account.refreshDepositSuccess'
    )

  withdrawAccountAssets = async (body) =>
    this.post(
      getApiRoute('accounts/withdraw'),
      body,
      'resource.account.withdrawAccountAssetsSuccess'
    )

  cancelOrder = async (orderId, successMessage = 'resource.order.cancelled') =>
    this.post(
      getApiRoute('orders/:id/cancel', { id: orderId }),
      {},
      successMessage
    )

  ordersBulkCreate = async (body) =>
    this.post(getApiRoute('orders/bulk-create'), body)

  ordersBulkForceRefresh = async (body) =>
    this.post(getApiRoute('accounts/open-orders/bulk-refresh'), body)

  ordersBulkCancel = async (body) =>
    this.post(getApiRoute('orders/bulk-cancel'), body)

  ordersBulkRefresh = async (body) =>
    this.post(getApiRoute('accounts/balance/bulk-refresh'), body)

  fetchAccountOpenOrders = async (accountId) =>
    this.get(getApiRoute('accounts/:id/open-orders', { id: accountId })).then(
      (response) => response.data['hydra:member']
    )

  fetchOrderTypes = async () =>
    this.get(getApiRoute('order-types'), {
      // TODO need to choice correct param
      perPage: 500,
      itemsPerPage: 500,
    }).then((response) => response.data['hydra:member'])

  fetchAccountRecentOrders = async (accountId) =>
    this.get(getApiRoute('accounts/:id/recent-orders', { id: accountId })).then(
      (response) => response.data['hydra:member']
    )

  fetchAccountBalance = async (accountId, options = {}) =>
    this.get(
      getApiRoute('accounts/:id/balance', { id: accountId }) +
        (options['forceBalance'] ? '?force=1' : '')
    ).then((response) => response.data['hydra:member'])

  fetchMarkets = async () =>
    this.get(getApiRoute('markets'), {
      properties: ['id', 'name', 'decimals', 'precision'],
      'order[name]': 'ASC',
      itemsPerPage: 500,
    }).then((response) => response.data['hydra:member'])

  fetchAccountHistory = async (accountId, fromDate, toDate) =>
    this.get(
      getApiRoute('export/accounts/:id/history', { id: accountId }) +
        `?from=${fromDate}&to=${toDate}`
    ).then((response) => response.data)

  exportKrakenLedgersInfo = async (accountId, type) =>
    this.get(
      getApiRoute('export/kraken/account/:id/ledgers/:type', {
        id: accountId,
        type,
      })
    ).then((response) => response.data)

  exportKrakenBalance = async (accountId) =>
    this.get(
      getApiRoute('export/kraken/account/:id/balance', { id: accountId })
    ).then((response) => response.data)

  fetchOpenOrders = async () =>
    this.get(getApiRoute('accounts/open-orders')).then(
      (response) => response.data['hydra:member']
    )

  fetchAllAccountsIds = async () =>
    this.get(
      getApiRoute('accounts') + '?properties[0]=id&active=1&itemsPerPage=500'
    ).then((response) => response.data['hydra:member'])

  fetchAllAccountsOverview = async () =>
    this.get(getApiRoute('accounts/overview')).then((response) => response.data)

  exportYtwrLastTwoMonths = async () =>
    this.get(getApiRoute('export/accounts/ytwr-last-two-months')).then(
      (response) => response.data['hydra:member']
    )

  export12m = async () =>
    this.get(getApiRoute('export/accounts/12-m-total-balance')).then(
      (response) => response.data
    )

  exportAllHistory = async (month) =>
    this.get(getApiRoute('export/accounts/history') + `?month=${month}`).then(
      (response) => response.data
    )

  exportAllInvoices = async (month, number) =>
    this.get(
      getApiRoute('export/accounts/invoices') +
        `?month=${month}&number=${number}`
    ).then((response) => response.data)

  exportBtcPriceHistory = async () =>
    this.get(getApiRoute('export/bitcoin/price-history')).then(
      (response) => response.data['hydra:member']
    )

  exportBprByMonth = async () =>
    this.get(getApiRoute('export/bitcoin/btwr-monthly')).then(
      (response) => response.data['hydra:member']
    )

  exportBtwrLifeTime = async () =>
    this.get(getApiRoute('export/bitcoin/btwr-lifetime')).then(
      (response) => response.data['hydra:member']
    )

  fetchExchanges = async () =>
    this.get(getApiRoute('exchanges')).then(
      (response) => response.data['hydra:member']
    )

  fetchCurrencies = async () =>
    this.get(getApiRoute('symbols') + '?isCurrency=1').then(
      (response) => response.data['hydra:member']
    )

  fetchInvoiceCreationList = async ({ date, closed = false, active = true }) =>
    this.get(
      `${getApiRoute(
        `invoices/list-for-creation/${date}`
      )}?closed=${closed}&active=${active}`
    ).then((response) => response.data['hydra:member'])

  invoicesBulkCreate = async (body) =>
    this.post(getApiRoute('invoices/bulk-create'), body)

  fetchInvoiceSendList = async ({ date, closed = false }) =>
    this.get(
      `${getApiRoute(`invoices/list-for-sending/${date}`)}?closed=${closed}`
    ).then((response) => response.data['hydra:member'])

  fetchInvoicePreSendList = async ({ date, closed = false }) =>
    this.get(
      `${getApiRoute(`invoices/list-pre-sending/${date}`)}?closed=${closed}`
    ).then((response) => response.data['hydra:member'])

  fetchAccountInvoices = async (accountId) =>
    this.get(`${getApiRoute(`invoices/list-for-account/${accountId}`)}`).then(
      (response) => response.data['hydra:member']
    )

  fetchInvoiceExtraLines = async (invoiceId) =>
    this.get(`${getApiRoute(`invoices/${invoiceId}/extra-lines`)}`).then(
      (response) => response.data['hydra:member']
    )

  updateInvoiceExtraLines = async (invoiceId, body = {}) =>
    this.patch(getApiRoute(`invoices/${invoiceId}/extra-lines`), body).then(
      (response) => response.data['hydra:member']
    )

  invoicesBulkSend = async (body) =>
    this.post(getApiRoute('invoices/bulk-send'), body)

  invoicesBulkPreSend = async (body) =>
    this.post(getApiRoute('invoices/bulk-pre-send'), body)

  invoicesBulkSetPaid = async (ids) =>
    this.post(getApiRoute('invoices/paid'), { ids })

  invoicesBulkSetUnPaid = async (ids) =>
    this.post(getApiRoute('invoices/un-paid'), { ids })

  fetchExactProperties = async () =>
    this.get(getApiRoute('exact-online/properties'))

  postOrder = async (reqData) => this.api.post(getApiRoute('orders'), reqData)

  postInvoice = async (accountId, month, number) =>
    this.get(
      getApiRoute('invoices/pdf/:accountId/:month/:number', {
        accountId,
        month,
        number,
      })
    ).then((response) => response.data)

  resetTOTP = async (userId) =>
    this.patch(getApiRoute('users/:id', { id: userId }), {
      totpSecret: null,
      tfaConfirmed: false,
    }).then((response) => response.data['hydra:member'])
}

export const api = new Api()

export default Api
