import { getFormattedError } from '../../mixin/hydra'
import _ from 'lodash'

export default (error) => {
  let message = _.get(error, 'response.data', 'Undefined Error')

  if ('object' === typeof message) {
    return getFormattedError(message, false)
  }

  if ('string' !== typeof message) {
    return JSON.stringify(error)
  }

  if (512 > message.length) {
    return message
  }
  const res = /<!--(.*?)-->/gm.exec(message)

  if (_.has(res, 1)) {
    return res[1]
  }

  return message.substring(0, 512) + '..'
}
