import TextField from '@material-ui/core/TextField'
import * as React from 'react'

export const Input = ({
  meta: { touched, error },
  input: inputProps,
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
)