import React from 'react';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { ChipField } from 'react-admin';
import get from 'lodash/get';

const styles = {
  error: {
    backgroundColor: 'rgba(194,54,45,0.72)',
    color: '#fff',
  },
  warning: {
    backgroundColor: 'rgba(238,90,0,0.74)',
    color: '#3a3a3a',
  },
  success: {
    backgroundColor: 'rgba(83,150,60,0.64)',
    color: '#fff',
  },
  notice: {
    backgroundColor: 'rgba(54,68,150,0.64)',
    color: '#fff',
  },
};

export const LevelField = withStyles(styles)(
  ({ classes, record, source, value }) => {
    if (!record) {
      return null;
    }

    const name = value || get(record, source);

    if (!name) {
      return null;
    }

    return (
      <ChipField
        className={classnames({
          [classes.error]: 'error' === name,
          [classes.warning]: 'warning' === name,
          [classes.notice]: 'notice' === name,
          [classes.success]: 'success' === name,
        })}
        style={{ width: '100px' }}
        source={source}
        record={record}
        clickable={false}
      />
    )
  }
);
