export default {
  login: {
    deny: 'The selected email address or password is incorrect.',
  },
  theme: {
    emptyList: 'No items available',
    emptyRef: 'N/A',
    back: 'Back',
  },
  resource: {
    // Base stuff
    details: 'Details',
    edit: 'Edit',
    id: 'ID',
    error: 'Error',
    createdBy: 'Created By',
    modifiedBy: 'Modified By',
    createdAt: 'Created At',
    modifiedAt: 'Modified At',
    updated: 'Record has been updated successfully.',
    created: 'Record has been created successfully.',
    date: 'Date',
    datetime: 'DateTime',
    cancel: 'Cancel',
    refresh: 'Refresh',
    forceRefresh: 'Force Refresh',
    done: 'Done',
    apply: 'Apply',
    export: 'Export',
    overview: 'Overview',
    total: 'Total',
    available: 'Available',
    provided: 'Provided',
    reserved: 'Reserved',
    accountsTotal: 'Accounts total',
    actions: 'Actions',
    monthAndYear: 'Month and Year',

    user: {
      menu: 'Users',
      field: 'User',
      createdBy: 'CreatedBy',
      modifiedBy: 'ModifiedBy',
    },

    notification: {
      menu: 'Notifications',
      field: 'Notification',
      message: 'Message',
      version: 'Plugin and Version',
    },

    plugin: {
      menu: 'Plugins',
      field: 'Plugin',
    },

    pluginVersion: {
      menu: 'Plugin Versions',
      slug: 'Version',
    },

    dashboard: {
      menu: 'Dashboard',
      title: 'Dashboard',
    },
  },
  tfa: {
    keyCopied: 'The key copied to the clipboard!',
    keyCopyError: 'We are sorry, but your browser is not supported!',
    keyHide: 'Hide the Key',
    keyShow: 'Show me the Key',
    copy: 'Copy to clipboard'
  }
}