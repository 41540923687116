import React from 'react'
import {
  required,
  usePermissions,
  TextInput,
} from 'react-admin'

import { CreateGuesser } from '../../theme/components'

export const PluginCreate = (props) => {
  const { permissions } = usePermissions()

  if (!permissions) {
    return null
  }

  return (
    <CreateGuesser{...props}>
      <TextInput
        source="slug"
        validate={[required()]}
      />
    </CreateGuesser>
  )
}
