import React from 'react'
import { HydraAdmin } from '@api-platform/admin'
import { Resource } from 'react-admin'

import { apiDocumentationParser } from './providers/dataProvider'
import { authProvider, dataProvider } from './providers'

import Layout from './component/layout/Layout'
import MuiTheme from './theme/MuiTheme'
import { Provider as i18nProvider } from './i18n/Provider'
import routes from './routes'
import user from './component/user'
import notification from './component/notification'
import plugin from './component/plugin'
import pluginVersion from './component/pluginVersion'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Dashboard } from './component/dashboard/Dashboard'
import { Login } from './component/auth/Login'
import { BASE_API_URL } from './service/Api/routes'

export default React.memo(() => (
  <>
      <CssBaseline />
      <HydraAdmin
        apiDocumentationParser={apiDocumentationParser}
        entrypoint={BASE_API_URL}
        authProvider={authProvider}
        dataProvider={dataProvider}
        i18nProvider={i18nProvider}
        theme={MuiTheme}
        layout={Layout}
        customRoutes={routes}
        dashboard={Dashboard}
        loginPage={Login}
      >
        {/*{(permissions) => {*/}
        {() => {
          let resources = [
            /* eslint-disable react/jsx-key */
            <Resource name="users" {...user} />,
            <Resource name="notifications" {...notification} />,
            <Resource name="plugins" {...plugin} />,
            <Resource name="plugin-versions" {...pluginVersion} />,
            /* eslint-enable react/jsx-key */
          ]

          return resources
        }}
      </HydraAdmin>
  </>
))
