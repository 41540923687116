import items from './items'
import { capitalizeFirstLetter } from '../../mixin/string'

const hasChildren = item => item.subItems && 0 < item.subItems.length
const stateName = item => `menu${capitalizeFirstLetter(item.resource)}`

export const menuItems = (permissions) => {
  if (!permissions) {
    return []
  }

  const menuItems = items(permissions)

  return menuItems.map(item => {
    item.hasChildren = hasChildren(item)

    if (item.hasChildren) {
      item.stateName = stateName(item)
    }

    return item
  })
}

export const menuStates = (items) =>
  items
    .filter(item => item.stateName)
    .map(item => item.stateName)
    .reduce((obj, item) => (
      {...obj, [item]: false}
    ), {})