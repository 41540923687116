import React, { forwardRef } from 'react'
import { DateField as RADateField } from 'react-admin'
import Tooltip from '@material-ui/core/Tooltip'
import get from 'lodash/get'
import { formatDate } from '../../../mixin/string'
import PropTypes from 'prop-types'

// eslint-disable-next-line no-unused-vars
const CustomRADateField = forwardRef((props, _ref) => {
  return <RADateField {...props} />
});

export const DateField = ({ record, source, locales, options, short, ...props }) => {
  if (!short) {
    return <CustomRADateField {...{...props, source, record, options, locales}} showTime={true} />
  }

  if (!record) {
    return null
  }

  const datetime = get(record, source)

  if (!datetime) {
    return null
  }

  let dateString = formatDate(datetime, locales, options)

  return (
    <Tooltip title={dateString}>
      <CustomRADateField {...{...props, source, record}} showTime={false} />
    </Tooltip>
  )
}

DateField.defaultProps = {
  locales: 'nl-Nl',
  short: true,
  options: { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' },
}

DateField.propTypes = {
  short: PropTypes.bool,
};