import DeveloperModeIcon from '@material-ui/icons/DeveloperMode'

import { PluginVersionList } from './PluginVersionList'
import { PluginVersionCreate } from './PluginVersionCreate'
import { PluginVersionEdit } from './PluginVersionEdit'

export default {
  list: PluginVersionList,
  create: PluginVersionCreate,
  edit: PluginVersionEdit,
  icon: DeveloperModeIcon,
}
