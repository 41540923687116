export const ROLES = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  IS_AUTHENTICATED_FULLY: 'IS_AUTHENTICATED_FULLY',
};

export const ROLE_LABELS = {
  ROLE_ADMIN: 'Admin',
};

export const roleOptions = (permissions) => {
  if (!permissions) {
    return []
  }

  if (permissions.isGranted([ROLES.ROLE_ADMIN])) {
    return [
      { id: ROLES.ROLE_ADMIN, name: ROLE_LABELS.ROLE_ADMIN },
    ]
  }

  return []
}
