import ExtensionIcon from '@material-ui/icons/Extension'

import { PluginList } from './PluginList'
import { PluginCreate } from './PluginCreate'
import { PluginEdit } from './PluginEdit'

export default {
  list: PluginList,
  create: PluginCreate,
  edit: PluginEdit,
  icon: ExtensionIcon,
}
