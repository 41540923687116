import React from 'react'
import {
  TextField,
  Filter,
  usePermissions,
  NumberInput,
  TextInput,
} from 'react-admin'
import { ListGuesser, DateField } from '../../theme/components'
import {ROLES} from '../../mixin/user'
import {VersionsField} from "./fields/VersionsField"

const ListFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Id" source="id"/>
    <TextInput
      source="slug"
    />
  </Filter>
)

export const PluginList = (props) => {
  const { permissions } = usePermissions()

  if (!permissions) {
    return null
  }

  return (
    <ListGuesser
      {...props}
      hasEdit={permissions.isGranted(ROLES.ROLE_ADMIN)}
      filters={<ListFilter permissions={permissions} />}
      filter={{
        properties: [
          'id',
          'slug',
          'versions',
          'createdAt',
        ],
        'properties[versions]': ['id', 'slug'],
      }}
      rowClick={permissions.isGranted(ROLES.ROLE_ADMIN) ? 'edit' : null}
      bulkActionButtons={false}
    >
      <TextField
        source="originId"
        label="id"
        sortBy="id"
        sort={{ field: 'id', order: 'DESC' }}
      />
      <TextField source="slug" sortBy="slug"/>
      <VersionsField label="Versions" />
      <DateField source="createdAt" sortBy="createdAt"/>
    </ListGuesser>
  )
}
